import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getParcelInfo } from '../services/index.js';
import { Footer as FooterV2 } from '../components/v2/Footer';
import { Header as HeaderV2 } from '../components/v2/Header';
import Carrousel from "../components/v2/carrousel";
import { ReactComponent as Chevron } from './../assets/chevron.svg';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export function ReturnParcel() {
    let { parcelId } = useParams();

    const [parcel, setParcel] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const datafetch = async () => {
            const data = await getParcelInfo(parcelId);
            process.env.REACT_APP_DEBUG === true && console.log(data);
            setParcel(data.results);
        };
        datafetch();
    }, [parcelId]);

    const style = {
        return: {
            backgroundColor: '#FFFFFF',
            width: '100%',
            padding: '0'
        },
        button: {
            borderRadius: '100px',
            padding: '0.8em',
            boxShadow: '0px 0px 16px 0px #878787',
            width: 'auto',
            fontSize: "1.2em",
            margin: ".5rem",
            alignItems: "center"
        },
        btnCont: {
            marginBottom: "9rem"
        }
    };
    //justify-content-between 
    
    const onClik = () => {
        window.open('https://localiser.laposte.fr/', '_blank');
    };

    return (
        <div className="return" style={style.return}>
            <HeaderV2 content='emballage' />
            <div className="container w-100 text-black p-4">
                <Carrousel content='emballage' />
                <div className="d-flex justify-content-between flex-wrap" style={style.btnCont}> 
                        <Button variant="secondary" type="button" className="btn btn-secondary fs-8 flex-fill" style={style.button} onClick={() => setShowModal(true)}>
                            Voir la vidéo
                        </Button>
                        <Button variant="primary" type="submit" onClick={onClik} className="d-flex flex-grow-1 fs-5 text-white justify-content-center" style={style.button}>
                            C'est plié !
                            <Chevron />
                        </Button>
                </div>
            </div>
            <FooterV2 content='emballage' tripCount={Number(parcel.usage)} tag={parcel.tag}/>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Tutoriel vidéo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ position: 'relative', paddingTop: "177.77777777777777%" }}>
                        <iframe
                            src="https://customer-w3g4fv6ckfw8v1zh.cloudflarestream.com/0781c7b11aebd69d38c582f9f5e253c2/iframe?poster=https%3A%2F%2Fcustomer-w3g4fv6ckfw8v1zh.cloudflarestream.com%2F0781c7b11aebd69d38c582f9f5e253c2%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                            loading="lazy"
                            style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowFullScreen
                            title='return video'
                            id='returnVideo'
                        ></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
