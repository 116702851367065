import React from 'react'
import { ReactComponent as EmballageSVG } from './../../assets/emballage.svg'
import { ReactComponent as ArticleSVG } from './../../assets/article.svg'
import { ReactComponent as IdentifiantSVG } from './../../assets/identifiant.svg'

export function Footer ({content = '', tripCount = 0, tag = 'MPXXXXXX'}) {

    const style = {
        footer: {
            marginTop: 'auto',
            minHeight: '8rem',
            bottom: '0',
            backgroundColor: '#2EA9C7',
            borderRadius: '5% 5% 0% 0%',
            boxShadow: '0px 0px 16px 0px #878787',
            width: '100%'
        },
        shipFooter: {
          whiteSpace: 'nowrap',
          display: 'inline', 
        },
        arrowFooter: {
          width: '100%',
          height: '100%',
          marginBottom: '-40px'
        }
    };

    const renderContent = (content) => {
      let tripCountText
      if (Number(tripCount)) {
        tripCountText = `Expédition${tripCount>1?"s":""} réalisée${tripCount>1?"s":""} : ${tripCount} / 100`
      } else {
        tripCountText = `Expédition réalisée : 1 / 100`
      }
        let carouselItems = []
        if( content === 'emballage') {
          return (
            <div className="fs-6 text-center text-white">
              <div className="row"> <EmballageSVG style={style.arrowFooter}/> </div>
              <div className="flex-row justify-content-center">
                <span className='fw-bold' style={style.shipFooter}>MOBIUS # </span>  
                <span className='fw-light' style={style.shipFooter}> {tag} </span> 
              </div>
              <div className="row justify-content-center mb-3">{tripCountText}</div>
            </div>
          );
        } else if (content === 'article') {
            return (
            <div className="fs-6 text-center text-white">
              <div className="row"> <ArticleSVG style={style.arrowFooter} /> </div>
              <div className="flex-row justify-content-center">
                <span className='fw-bold' style={style.shipFooter}>MOBIUS # </span>  
                <span className='fw-light' style={style.shipFooter}> {tag} </span> 
              </div>
              <div className="row justify-content-center mb-3">{tripCountText}</div>
            </div>)
        }
        else if (content === 'home') {
            carouselItems = ['En savoir plus sur MOBIUSpack et sont engagement']
            return carouselItems.map((item, index) => (
              <div className="fs-6 text-center text-white" key={index}>
                <div className="row justify-content-center"> {item} </div>
              </div>
            ));
        }
        else if (content === 'identifiant') {
            return (
              <div className="fs-6 text-center text-white" >
                <div className="row"> <IdentifiantSVG style={style.arrowFooter}/> </div>
                <div className="flex-row justify-content-center">
                  <span className='fw-bold' style={style.shipFooter}>MOBIUS # </span>  
                  <span className='fw-light' style={style.shipFooter}> {tag} </span> 
                </div>
                <div className="row justify-content-center mb-3">{tripCountText}</div>
              </div>
            );
        }
        else {
          return (<div></div>)
        }

    }

    return <div className="footer containe fixed-bottom" style={style.footer}>
        <div className="row">
            <div className="col align-self-end mt-4"> 
                {renderContent(content)}
            </div>
        </div>
    </div>

}

export default Footer;
